<template>
  <div>
    <div class="row mb-2">
      <div class="col-md-12 text-right">
        
      </div>
    </div>
    <b-table
      v-if="customerPlotDetails.length !== 0"
      id="my-table"
      class="dataTable rounded-left"
      :outlined="true"
      :items="customerPlotDetails"
      :fields="fields"
      head-variant="none"
      table-variant="none"
      :caption-top="true"
    >
      <template #table-caption>User Plots</template>
      <template #cell(#)="data">{{ data.index + 1 }}</template>
      <template #cell(actions)="row">
        <b-dropdown
          id="dropdown-1"
          text="Dropdown Button"
          class="m-md-2"
          size="sm"
          variant="success"
          no-caret
        >
          <template #button-content>
            <b-icon icon="three-dots-vertical" aria-hidden="true"></b-icon>
          </template>
          <b-dropdown-item
            :to="'/users/' + row.item._user_id + '/plot/' + row.item._id"
          >
            View
          </b-dropdown-item>
         
        </b-dropdown>
      </template>
    </b-table>

    <h4 class="text-uppercase font-weight-bold">
      Total Area: <span class="text-success">{{ totalArea }} </span>acrs
    </h4>
  </div>
</template>
<script>
export default {
  data() {
    return {
      fields: [
        '#',
        {
          key: '_project_id.name',
          label: 'Project',
        },
        {
          key: '_zone_id.code',
          label: 'Zone',
        },
        {
          key: '_plot_id.code',
          label: 'Plot',
        },
        {
          key: '_plot_id.area',
          label: 'Area(acrs)',
        },
        {
          key: '_plot_id.documentations.loi',
          label: 'Letter of Intent',
          formatter: (value) => (value ? 'Completed' : 'Pending'),
        },
        {
          key: '_plot_id.documentations.sale_agreement',
          label: 'Sale Agreement',
          formatter: (value) => (value ? 'Completed' : 'Pending'),
        },
        {
          key: '_plot_id.documentations.sale_deed',
          label: 'Sale Deed',
          formatter: (value) => (value ? 'Completed' : 'Pending'),
        },
        {
          key: '_plot_id.documentations.service_agreement',
          label: 'Service Agreement',
          formatter: (value) => (value ? 'Completed' : 'Pending'),
        },
        {
          key: '_plot_id.documentations.membership_agreement',
          label: 'Membership Agreement',
          formatter: (value) => (value ? 'Completed' : 'Pending'),
        },

        'actions',
      ],
    };
  },
  props: ['customerPlotDetails'],

  methods: {
    dissassociatePlot(data) {
      let prompt = confirm('Are you sure want to delete ? ');
      if (prompt) {
        console.log(data);
        window.axios.delete('/customer-plot/' + data._id).then((resp) => {
          if (resp.data.success) {
            this.$emit('refreshPlots', true);
            alert(resp.data.message);
          }
        });
      }
    },
  },
  computed: {
    totalArea() {
      let totalArea = 0;
      for (let i in this.customerPlotDetails) {
        totalArea =
          totalArea + parseFloat(this.customerPlotDetails[i]._plot_id.area);
      }
      return totalArea.toFixed(2);
    },
  },
};
</script>

<style scoped>
/deep/ .dataTable {
  margin-left: 2px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

/deep/ .dataTable thead {
  background-color: #e6e6e6;
  height: 51px;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 3.5%;
  font-family: 'Poppins', sans-serif;
  padding-top: 14px;
  padding-bottom: 14px;
}

/deep/ .dataTable {
  text-align: center;
  margin-bottom: 21.5px;
  margin-left: 2px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}
/deep/ .dataTable tr td {
  font-family: 'Poppins', sans-serif;
  font-size: 17px;
  letter-spacing: 3.5%;
  line-height: 25.5px;
  font-weight: 400;
  padding-top: 12px;
  padding-bottom: 12px;
}

/deep/ .dataTable caption {
  border-radius: 10px 10px 0 0;
  height: 64px;
  width: 100%;
  background-color: #dcffd9;
  color: #000;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  letter-spacing: 3.5%;
  padding-left: 27px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 25px;
}
</style>
