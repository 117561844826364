<template>
  <div class="mt-2 p-2" style="background-color:white;padding:10px">
    <b-container fluid class="mb-2">
      <user-details :userDetails="userDetails"></user-details>
      <div v-if="userDetails._userTypeID.name == 'Customer'">
        <user-plots :customerPlotDetails="customerPlotDetails"></user-plots>
      </div>
      <h4 class="text-uppercase font-weight-bold">
        Total Credits:
        <span class="text-success">{{ userDetails.TotalCredits }} </span>
      </h4>
    </b-container>
  </div>
</template>
<script>
import userDetails from "@/components/users/details";
import userPlots from "@/components/users/plots/list";
export default {
  components: {
    userDetails,
    userPlots,
  },
  data() {
    return {
      userDetails: false,
      customerPlotDetails: false,
    };
  },
  mounted() {
    this.getUserDetails();
    this.getUserPlotDetails();
  },
  methods: {
    getUserDetails() {
      console.log(this.$route.params.id);
      let id = this.$route.params.id;
      window.axios.get("user/" + id).then((resp) => {
        console.log(resp);
        this.userDetails = resp.data.data;
      });
    },
    getUserPlotDetails() {
      console.log(this.$route.params.id);
      let id = this.$route.params.id;
      window.axios.get("/customer-plots/" + id).then((resp) => {
        console.log(resp);
        this.customerPlotDetails = resp.data.data;
      });
    },
  },
};
</script>
