<template>
  <div>
    <b-row class="mb-3">
      <b-col md="4">
        <b-card class="user-card  border-0">
          <b-card-header
            class="d-flex justify-content-center card-header--background mb-5 pt-5 "
          >
            <b-avatar
              variant="success"
              :text="
                userDetails.name.charAt(0) === ' '
                  ? userDetails.name.charAt(1)
                  : userDetails.name.charAt(0)
              "
              size="6rem"
            ></b-avatar>
          </b-card-header>

          <b-card-text class="text-center">
            <h2>{{ userDetails.name }}</h2>
            <span class="d-block text-muted">{{ userDetails.email }}</span>
            <span class="d-block text-muted">{{ userDetails.phone }}</span>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col md="8">
        <b-card class="user-details border-0">
          <b-card-text>
            <table class="table table-sm ">
              <tr>
                <th>Full Name</th>
                <td>{{ userDetails.name }}</td>
              </tr>
              <tr>
                <th>Email</th>
                <td>{{ userDetails.email }}</td>
              </tr>
              <tr>
                <th>Phone</th>
                <td>{{ userDetails.phone }}</td>
              </tr>
              <tr>
                <th>Date of Birth</th>
                <td>
                  <span v-if="userDetails.dob">{{
                    userDetails.dob | moment("ddd,Do MMM  YYYY")
                  }}</span>
                </td>
              </tr>
              <tr>
                <th>Total Credits</th>
                <td>{{ userDetails.TotalCredits }}</td>
              </tr>
              <tr>
                <th>Available Credits</th>
                <td>{{ userDetails.CurrentCredits }}</td>
              </tr>
              <tr>
                <th>Member Since</th>
                <td>
                  {{ userDetails.member_since | moment("ddd,Do MMM  YYYY") }}
                </td>
              </tr>
            </table>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  props: ["userDetails"],
};
</script>

<style scoped>
.user-card .card-body {
  padding: 0 0 48px 0;
  border-radius: 11px;
  border: 1px solid #13e78f;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.user-details .card {
  border-radius: 11px;
}

.user-details .card-body {
  background-color: #dcffd9;
  border-radius: 11px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.card-header--background {
  background-color: #13e78f;
  height: 6.5rem;
  width: 100%;
  border-bottom: none;
  border-radius: 11px 11px 0 0;
}

.table > tr:first-child > td,
.table > tr:first-child > th {
  border: none;
}
</style>
